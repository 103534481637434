<template>
  <section>

    <BodyText :body="message.body"></BodyText>
    
    <div  v-if="message.attachments.length != 0">
      <hr class="pb-0 mb-0 mt-2" />

      <div class="info-header mt-2">{{ $t("ATTACHMENTS") }}</div>

      <div class="mt-2">
        <span v-for="(item, index) in message.attachments" :key="index">
          <Attachment
            :item="item"
            :message_uuid="message.message_uuid"
            :user_uuid="user_uuid"
            :filestorage="filestorage"
            :functionbox_uuid="functionbox_uuid"
            :index="index"
          ></Attachment>
        </span>
      </div>    
    </div>

    <MetadataSdk v-if="metadata"
      :functionbox_uuid="functionbox_uuid"
      :metadata="metadata"></MetadataSdk>        

  </section>
</template>
<script>
import Attachment from "../Attachment";
import BodyText from "../BodyText";
import MetadataSdk from "./Metadata/SdkMetadata";
export default {
  props: ["external_system_participants", "filestorage","message","user_uuid", "functionbox_uuid"],
  components: {
    Attachment,
    BodyText,
    MetadataSdk
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    metadata(){
      if(this.external_system_participants.length != 0){
        return this.external_system_participants[0].metadata;
      }else{
        return this.message.creator.external_system.metadata;
      }
    },
    showSdkMetadata(){
      return this.message.creator.external_system.participantType == "SDK";
    }
  },
  watch: {},
  mounted: function () {
  },
};
</script>
<style></style>
