var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info
    ? _c(
        "span",
        [
          _c("ContactInformation", {
            attrs: {
              "no-lookup": _vm.noLookup,
              user_uuid: _vm.user_uuid,
              functionbox_uuid: _vm.functionbox_uuid,
              identifier: _vm.info.email,
            },
          }),
          !_vm.islast ? _c("span", [_vm._v("; ")]) : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }