<template>
  <section>

    <dl class="mt-2" v-if="metadata">
      <dt>{{ $t("FROM") }}</dt>
      <dd>{{  metadata.FromFaxName }}<span v-if="metadata.FromFaxName">, </span>{{ metadata.FromFaxNumber.replace("00","+") }}</dd>
      <dt>{{ $t("TO") }}</dt>
      <dd>{{  metadata.ToFaxName }}<span v-if="metadata.ToFaxName">, </span>{{ metadata.ToFaxNumber.replace("00","+") }}</dd>
      <div v-if="1 == 0">
        <dt v-if="metadata.SenderFunctionBoxIdentifier">{{ $t("FAX.CLIENT_IDENTIFIER") }}</dt>
        <dd v-if="metadata.SenderFunctionBoxIdentifier">{{ metadata.SenderFunctionBoxIdentifier }}</dd>
        <dt v-if="metadata.RecipientFunctionBoxIdentifier">{{ $t("FAX.CLIENT_IDENTIFIER") }}</dt>
        <dd v-if="metadata.RecipientFunctionBoxIdentifier">{{ metadata.RecipientFunctionBoxIdentifier }}</dd>
      </div>
    </dl>

  </section>
</template>
<script>
export default {
  props: ["metadata"],
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
  watch: {},
  mounted: function () {},
};
</script>
<style></style>
