var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loa_level != 0
    ? _c(
        "section",
        { staticClass: "loa-information" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("b-alert", { attrs: { show: "" } }, [
                    _vm._v(_vm._s(_vm.$t("LOA.CONTACT"))),
                  ]),
                  _c("b-card", [
                    _c("div", { staticClass: "mt-2 mb-" }, [
                      _c("p", { staticClass: "bodytext" }, [
                        _vm._v(_vm._s(_vm.$t("LOA.DESCRIPTION"))),
                      ]),
                      _c("p", { staticClass: "bodytext" }, [
                        _vm._v(_vm._s(_vm.$t("LOA.EXAMPLE"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                _vm.loa3list.length != 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-card",
                          {
                            attrs: { "no-body": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("LOA.NAME")) + " 3 "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2218774374
                            ),
                          },
                          [
                            _c(
                              "b-list-group",
                              { attrs: { flush: "" } },
                              _vm._l(_vm.loa3list, function (item, index) {
                                return _c("b-list-group-item", { key: index }, [
                                  _vm._v(_vm._s(item.text)),
                                ])
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.loa2list.length != 0
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-card",
                          {
                            attrs: { "no-body": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("LOA.NAME")) + " 2 "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3177547815
                            ),
                          },
                          [
                            _c(
                              "b-list-group",
                              { attrs: { flush: "" } },
                              _vm._l(_vm.loa2list, function (item, index) {
                                return _c("b-list-group-item", { key: index }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.text) +
                                      "\n              "
                                  ),
                                  _vm.user.information.authentication_type ==
                                  item.value
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fal fa-arrow-left",
                                        }),
                                        _vm._v(" "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.$t("LOA.CURRENT_LEVEL"))
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ])
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.loa1list.length != 0
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-card",
                          {
                            attrs: { "no-body": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("LOA.NAME")) + " 1 "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2162489828
                            ),
                          },
                          [
                            _c(
                              "b-list-group",
                              { attrs: { flush: "" } },
                              _vm._l(_vm.loa1list, function (item, index) {
                                return _c("b-list-group-item", { key: index }, [
                                  _vm._v(_vm._s(item.text)),
                                ])
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }