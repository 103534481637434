<template>
  <section>    
    <div v-if="message.attachments.length != 0">
      

      <div class="info-header mt-2">{{ $t("ATTACHMENTS") }}</div>

      <span v-for="(item, index) in message.attachments" :key="index">
        <Attachment
          :item="item"
          :message_uuid="message.message_uuid"
          :user_uuid="user_uuid"
          :functionbox_uuid="functionbox_uuid"
          :index="index"
          :filestorage="filestorage"
        ></Attachment>
      </span>
    </div>
    <hr class="pb-0 mb-0 mt-2" />
    <MetadataFax class="clearfix mt-2" 
       v-if="metadata"
      :functionbox_uuid="functionbox_uuid"
      :metadata="metadata"></MetadataFax>
  </section>
</template>
<script>
import Attachment from "../Attachment";
import MetadataFax from "../Information/Metadata/FaxMetadata";
export default {
  name: "MessageInformation",
  props: ["external_system_participants", "filestorage","message", "user_uuid", "functionbox_uuid"],
  components: {
    Attachment,
    MetadataFax
  },
  data() {
    return {
    };
  },
  computed: {
    metadata(){
      if(this.external_system_participants.length != 0){
        return this.external_system_participants[0].metadata;
      }else{
        if(this.message.creator.external_system != undefined)
        {
          return this.message.creator.external_system.metadata;          
        }
        return null;
      }
    },
  },
  methods: {
  },
  watch: {},
  mounted: function () {},
};
</script>
<style></style>
