<template>
  <section class="loa-information" v-if="loa_level != 0">
    <b-row>
      <b-col cols="12" md="6">
        <b-alert show>{{ $t("LOA.CONTACT") }}</b-alert>
        <b-card>
          <div class="mt-2 mb-">
            <p class="bodytext">{{ $t("LOA.DESCRIPTION") }}</p>
            <p class="bodytext">{{ $t("LOA.EXAMPLE") }}</p>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <div v-if="loa3list.length != 0">
          <b-card no-body>
            <template #header> {{ $t("LOA.NAME") }} 3 </template>
            <b-list-group flush>
              <b-list-group-item
                v-for="(item, index) in loa3list"
                :key="index"
                >{{ item.text }}</b-list-group-item
              >
            </b-list-group>
          </b-card>
        </div>

        <div v-if="loa2list.length != 0" class="mt-2">
          <b-card no-body>
            <template #header> {{ $t("LOA.NAME") }} 2 </template>
            <b-list-group flush>
              <b-list-group-item v-for="(item, index) in loa2list" :key="index">
                {{ item.text }}
                <span v-if="user.information.authentication_type == item.value">
                  <i class="fal fa-arrow-left"></i>&nbsp;<b>{{
                    $t("LOA.CURRENT_LEVEL")
                  }}</b></span
                >
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>

        <div v-if="loa1list.length != 0" class="mt-2">
          <b-card no-body>
            <template #header> {{ $t("LOA.NAME") }} 1 </template>
            <b-list-group flush>
              <b-list-group-item
                v-for="(item, index) in loa1list"
                :key="index"
                >{{ item.text }}</b-list-group-item
              >
            </b-list-group>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>
export default {
  props: ["loa_level"],
  data() {
    return {
      loading: false,
      loa3list: [],
      loa2list: [],
      loa1list: [],
    };
  },
  methods: {
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/external/methods")
        .then(function (result) {
          let methods = result.data;
          for (let ix = 0; ix < methods.length; ix++) {
            let method = methods[ix];
            if (method.loa_level == 3) {
              self.loa3list.push({
                value: method.name,
                text: self.$t("AUTH_METHODS." + method.auth_type),
              });
            }
          }
          self.loa2list.push({
            value: "password",
            text: self.$t("AUTH_METHODS.password"),
          });
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.getMethods();
  },
};
</script>
<style></style>
