<template>
  <section class="view-content">
    <b-overlay :show="loading" rounded="sm">

      <div v-if="opened" style="position: relative">
        <div class="text-center" v-if="showNewMessages">
          <b-button class="no-border" @click="showConversation">
            <i class="fal fa-chevron-double-up"></i> &nbsp;
            {{ responses_after }} {{ $t("CONVERSATION_NEW_MESSAGES") }}
          </b-button>
        </div>

        <b-alert v-if="SdkConfidential" class="mb-1" show variant="warning" >{{ $t("SDK.IsConfidential") }}</b-alert>

        <b-alert
          v-if="!functionbox_uuid && showArchived"
          show
          variant="secondary"
          class="mb-1"
          >{{ $t("CLOSED_CONVERSATION") }}
        </b-alert>

        <b-alert
          v-if="functionbox_uuid && showArchived"
          show
          variant="secondary"
          class="mb-1"
          >{{ $t("CLOSED_ISSUE") }}
        </b-alert>

        <b-alert
          v-if="
            functionbox_uuid &&
            claimedBy &&
            claimedBy.email == user.information.email
          "
          show
          variant="info"
          >{{ $t("CLAIMED_BY") }} {{ $t("ME") }}</b-alert
        >

        <b-alert
          v-if="
            functionbox_uuid &&
            claimedBy &&
            claimedBy.email != user.information.email
          "
          show
          variant="info"
          >{{ $t("CLAIMED_BY") }} {{ claimedBy.email }}</b-alert
        >

        <div class="mt-2">
          <b-button
            v-if="showCloseBtn"
            size="sm"
            @click="closeMessage"
            class="no-border"
          >
            <i class="fal fa-times"></i
            ><span class="hidden-mobile">&nbsp;{{ $t("CLOSE") }}</span>
          </b-button>

          <b-button
            v-if="isConversation"
            size="sm"
            @click="gotoMessage"
            class="no-border d-print-none"
          >
            {{ $t("GOTO_MESSAGE") }}
          </b-button>

          <b-button
            @click="getInformation"
            class="no-border"
            size="sm"
            v-if="!isConversation"
          >
            <i class="fal fa-sync"></i
            ><span class="hidden-mobile">&nbsp;{{ $t("UPDATE") }}</span>
          </b-button>

          <b-dropdown
            size="sm"
            id="dropdown-change-meeting"
            :text="$t('CHANGE')"
            class=""
            toggle-class="no-border"
            v-if="IsMeeting(message.message_type) && isCreator"
          >
            <b-dropdown-item @click="$refs.changeMeetingInformation.click()">{{ $t("CHANGE_MEETING_INFORMATION") }}</b-dropdown-item>
            <b-dropdown-item @click="$refs.changeMeetingFiles.click()">{{ $t("CHANGE_MEETING_FILES") }}</b-dropdown-item>
            <b-dropdown-item @click="$refs.changeMeetingTime.click()">{{ $t("CHANGE_TIME") }}</b-dropdown-item>

            <b-dropdown-item v-if="canRemove && IsMeeting(message.message_type) && isCreator" @click="removeMeeting">
              {{ $t("CANCEL_MEETING") }}</b-dropdown-item
            >
          </b-dropdown>

          <ChangeMeeting
              @ChangeShow="UpdateInformation"
              @Changed="UpdateInformation"
              :recurrence="recurrence"
              :message="message"
              is-change
              :startMeeting="startDate"
              :endMeeting="endDate"
              ><b-button size="sm" ref="changeMeetingInformation" class="hidden-mobile hidden-not-mobile">
                <i class="fal fa-pen-to-square"></i
                ><span class="hidden-mobile"
                  >&nbsp;{{ $t("CHANGE_MEETING_INFORMATION") }}</span
                ></b-button>
              </ChangeMeeting>

            
          <ChangeMeeting
          v-if="IsMeeting(message.message_type) && isCreator"
          @ChangeShow="UpdateInformation"
          @Changed="UpdateInformation"
          change-attachments
          :recurrence="recurrence"
          :message="message"
          :startMeeting="startDate"
          :endMeeting="endDate"
          ><b-button size="sm" ref="changeMeetingFiles" class="hidden-mobile hidden-not-mobile">
            ><i class="fal fa-pen-to-square"></i
            ><span class="hidden-mobile"
              >&nbsp;{{ $t("CHANGE_MEETING_FILES") }}</span
            ></b-button
          ></ChangeMeeting>

                 
          <ChangeMeeting
          v-if="IsMeeting(message.message_type) && isCreator"
          @ChangeShow="UpdateInformation"
          @Changed="UpdateInformation"
          change-time
          :recurrence="recurrence"
          :message="message"
          :startMeeting="startDate"
          :endMeeting="endDate"
          ><b-button size="sm" ref="changeMeetingTime" class="hidden-mobile hidden-not-mobile">
            ><i class="fal fa-pen-to-square"></i
            ><span class="hidden-mobile"
              >&nbsp;{{ $t("CHANGE_TIME") }}</span
            ></b-button
          ></ChangeMeeting>

          <AddMessageReply
            v-if="showComplete"
            @Changed="UpdateInformation"
            :message="message"
            reply-complete
            :functionbox_uuid="functionbox_uuid"
            :user_uuid="user_uuid"
            ><b-button size="sm" class="no-border"
              ><i class="fal fa-reply-all"></i
              ><span class="hidden-mobile"
                >&nbsp;<span v-if="isCreatorParticipant">{{ $t("RESPOND") }}AA</span><span v-if="!isCreatorParticipant">{{ $t("COMPLETE") }}</span></span
              ></b-button
            ></AddMessageReply
          >

          <AddMessageReply
            v-if="showRespond"
            @Changed="UpdateInformation"
            :message="message"
            reply
            :functionbox_uuid="functionbox_uuid"
            :user_uuid="user_uuid"
            ><b-button size="sm" class="no-border"
              ><i class="fal fa-reply"></i
              ><span class="hidden-mobile"
                >&nbsp;{{ $t("RESPOND") }}</span
              ></b-button
            ></AddMessageReply
          >

          <AddMessageReply
            v-if="showRespondAll"
            @Changed="UpdateInformation"
            :message="message"
            reply-all
            :functionbox_uuid="functionbox_uuid"
            :user_uuid="user_uuid"
            ><b-button size="sm" class="no-border"
              ><i class="fal fa-reply-all"></i
              ><span class="hidden-mobile"
                >&nbsp;{{ $t("RESPOND_ALL") }}</span
              ></b-button
            ></AddMessageReply
          >

          <AddMessageForward
            v-if="showForward"
            @Changed="UpdateInformation"
            :message="message"
            :functionbox_uuid="functionbox_uuid"
            :user_uuid="user_uuid"
            ><i class="fal fa-share"></i
            ><span class="hidden-mobile"
              >&nbsp;{{ $t("FORWARD") }}</span
            ></AddMessageForward
          >

          <b-dropdown
            size="sm"
            id="dropdown-1"
            :text="$t('MANAGE')"
            class=""
            toggle-class="no-border"
            v-if="message && !isConversation"
          >
            <b-dropdown-item
              v-if="showClaimBtn"
              @click="claim"
              >{{ $t("CLAIM") }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="showRemoveClaimBtn"
              @click="noClaim"
              >{{ $t("REMOVE_CLAIM") }}</b-dropdown-item
            >

            <b-dropdown-item
              v-if="showCloseConversationBtn"
              @click="archive"
              >{{ $t("CLOSE_CONVERSATION") }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="showNoArchiveBtn"
              @click="noArchive"
              >{{ $t("REOPEN_CONVERSATION") }}</b-dropdown-item
            >

            <b-dropdown-item
              v-if="showCloseIssueBtn"
              @click="closeIssue"
              >{{ $t("CLOSE_ISSUE") }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="showOpenIssueBtn"
              @click="noArchive"
              >{{ $t("OPEN_ISSUE") }}</b-dropdown-item
            >

            <b-dropdown-divider
              v-if="IsMessage(message.message_type) && (showClaimBtn || showRemoveClaimBtn || showCloseConversationBtn || showNoArchiveBtn || showCloseIssueBtn || showOpenIssueBtn)"
            ></b-dropdown-divider>

            <b-dropdown-item
              :href="direktLink"
              class="no-border"
              target="_blank"
            >
              {{ $t("OPEN_NEW_TAB") }}
            </b-dropdown-item>
            <b-dropdown-item @click="printBtn">
              {{ $t("PRINT") }}
            </b-dropdown-item>
            <b-dropdown-divider
              v-if="functionbox_uuid && canRemove"
            ></b-dropdown-divider>

            <b-dropdown-item v-if="canRemove && !IsMeeting(message.message_type)" @click="removeMessage">
              {{ $t("DELETE") }}</b-dropdown-item
            >
          </b-dropdown>
        </div>

        <div id="printMe">
          <b-container fluid class="ml-0 mr-0 pl-0 pr-0">
            <b-row no-gutters>
              <b-col :md="mdcols" sm="12" class="ml-0 pl-0 pr-2">
                <b-card class="mt-2 card-message" ref="printBody">
                  <template v-slot:header v-if="!isConversation">
                    <div class="text-break">
                      <img
                        class="icon mr-2"
                        :src="iconImage"
                        :alt="message.subject"
                      />
                      <span v-html="message.subject"></span>
                    </div>
                  </template>
                  <UserInfo
                    class="mt-2"
                    image="/img/default-avatar.png"
                    :primary="createdName"
                    :secondary="message.creator.email"
                    :optional="createdTime"
                  ></UserInfo>
                  <small v-if="functionBoxCreatedBy != ''"
                    >{{ $t("CREATED_BY") }} {{ functionBoxCreatedBy }}</small
                  >
                  <small v-if="delegatedCreatedBy != ''"
                    >{{ $t("CREATED_BY") }} {{ delegatedCreatedBy }}</small
                  >

                  <Recipients
                    v-if="showRecipients && !isCreator"
                    :message="message"
                    :isCreator="false"
                    :showRecalled="showRecalled"
                    :participants="participants"
                    :external_system_participants="external_system_participants"
                    :external="external"
                  ></Recipients>

                  <hr class="pb-0 mb-0 mt-2" />
                  <RegularMessage
                    :message="message"
                    :user_uuid="user_uuid"
                    :filestorage="filestorage"
                    :functionbox_uuid="functionbox_uuid"
                    v-if="regular_message"
                  ></RegularMessage>
                  <FaxMessage
                    :message="message"
                    :user_uuid="user_uuid"
                    :filestorage="filestorage"
                    :functionbox_uuid="functionbox_uuid"
                    v-if="message.message_type == 'fax-message'"
                    :external_system_participants="external_system_participants"
                  ></FaxMessage>
                  <SdkMessage
                    :message="message"
                    :user_uuid="user_uuid"
                    :filestorage="filestorage"
                    :functionbox_uuid="functionbox_uuid"
                    v-if="message.message_type == 'sdk-message'"
                    :external_system_participants="external_system_participants"
                  ></SdkMessage>
                  <SystemMessage
                    :message="message"
                    :user_uuid="user_uuid"
                    :functionbox_uuid="functionbox_uuid"
                    v-if="message.message_type == 'system-message'"
                  ></SystemMessage>
                  <TeamsMeeting
                    :message="message"
                    :user_uuid="user_uuid"
                    :startMeeting="startDate"
                    :endMeeting="endDate"
                    :recurrence="recurrence"
                    :filestorage="filestorage"
                    :functionbox_uuid="functionbox_uuid"
                    v-if="message.message_type == 'teams-meeting'"
                  ></TeamsMeeting>
                  <NuiteqMeeting
                    :message="message"
                    :user_uuid="user_uuid"
                    :startMeeting="startDate"
                    :endMeeting="endDate"
                    :recurrence="recurrence"
                    :filestorage="filestorage"
                    :functionbox_uuid="functionbox_uuid"
                    v-if="message.message_type == 'nuiteq-meeting'"
                  ></NuiteqMeeting>
                  <JitsiMeeting
                    :message="message"
                    :user_uuid="user_uuid"
                    :startMeeting="startDate"
                    :endMeeting="endDate"
                    :recurrence="recurrence"
                    :filestorage="filestorage"
                    :functionbox_uuid="functionbox_uuid"
                    v-if="message.message_type == 'jitsi-meeting'"
                  ></JitsiMeeting>
                  <JitsiSecureMeeting
                    :message="message"
                    :user_uuid="user_uuid"
                    :startMeeting="startDate"
                    :endMeeting="endDate"
                    :recurrence="recurrence"
                    :filestorage="filestorage"
                    :functionbox_uuid="functionbox_uuid"
                    v-if="message.message_type == 'jitsi-secure-meeting'"
                  ></JitsiSecureMeeting>

                  <template v-slot:footer>
                    <a @click="show = true" class="d-print-none clickable"
                      ><i class="ml-2 fal fa-info-circle"></i>
                      {{ $t("DETAILS") }}</a
                    >

                    <FunctionBoxAccounts
                      v-if="functionbox_uuid"
                      :message_uuid="message_uuid"
                      :functionbox_uuid="functionbox_uuid"
                      :functionbox_accounts="functionbox_accounts"
                      ><i class="ml-2 fal fa-info-circle"></i>
                      {{ $t("FUNCTIONBOX_USERS") }}</FunctionBoxAccounts
                    >

                    <a
                      @click="showFunctionboxLog = true"
                      v-if="functionbox_uuid"
                      class="clickable"
                      ><i class="ml-2 fal fa-info-circle"></i>
                      {{ $t("LOGG") }}</a
                    >
                    <DelegatedAccounts
                      v-if="delegated_accounts.length != 0"
                      :message_uuid="message_uuid"
                      :user_uuid="user_uuid"
                      :delegated_accounts="delegated_accounts"
                      ><i class="ml-2 fal fa-info-circle"></i>
                      {{ $t("FUNCTIONBOX_USERS") }}</DelegatedAccounts
                    >
                    <a
                      @click="showDelegatedAccountLog = true"
                      v-if="delegated_accounts.length != 0"
                      class="clickable"
                      ><i class="ml-2 fal fa-info-circle"></i>
                      {{ $t("LOGG") }}</a
                    >
                  </template>
                </b-card>
              </b-col>
              <b-col md="6" sm="12" class="mr-0 pr-0" v-if="ParticipantNoCreatorCount != 0 && showRecipients && !isConversation && isCreator">
                <b-card class="mt-2 card-message card-titleform" no-body>
                  <template v-slot:header>
                    {{ $t("RECIPIENTS") }}
                  </template>

                  <Recipients
                    :message="message"
                    :isCreator="isCreator"
                    :showRecalled="showRecalled"
                    :participants="participants"
                    :external_system_participants="external_system_participants"
                    :external="external"
                  ></Recipients>
                </b-card>

                <b-form-checkbox
                v-if="!IsMeeting(message.message_type) && isCreator"
                id="showRecalled"
                switch
                v-model="showRecalled"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t("SHOW_RECALLED") }}
              </b-form-checkbox>

              </b-col>
            </b-row>
          </b-container>

          <b-sidebar
            id="message-information"
            v-model="show"
            lazy
            right
            no-slide
            shadow
            backdrop
          >
            <div class="px-3 py-2">
              {{ $t("MESSAGE_DETAILS") }}

              <hr class="pb-0 mb-0 mt-2 mb-3" />

              <h6 class="mb-0 no-uppercase">{{ $t("MESSAGE_UUID") }}</h6>
              {{ message.message_uuid }}

              <div v-if="message.creator.name != ''">
                <h6 class="mt-3 mb-0 no-uppercase">{{ $t("CREATOR_UUID") }}</h6>
                {{ message.creator.user_uuid }}
              </div>

              <div v-if="functionbox_uuid">
                <h6 class="mt-3 mb-0 no-uppercase">
                  {{ $t("RECIPIENT") }} {{ $t("FUNCTIONBOX.TITLE") }}
                </h6>
                {{ functionbox_uuid }}
              </div>

              <h6 class="mt-3 mb-0 no-uppercase">
                {{ $t("MESSAGE_VERSION") }}
              </h6>
              {{ message.version }}

              <div v-if="!responseType">
                <h6 class="mt-3 mb-0 no-uppercase">
                  {{ $t("LOA.TITLE_PARTICIPANT") }}
                </h6>
                <i
                  class="fal fa-info-square"
                  v-b-tooltip.hover
                  :title="$t('LOA.INFO.' + message.settings.loa_level)"
                ></i>
                LOA{{ message.settings.loa_level }}
              </div>

              <h6 class="mt-3 mb-0 no-uppercase">{{ $t("TYPE") }}</h6>
              {{ $t("MESSAGES.TYPE." + message.message_type) }}

              <h6
                class="mt-3 mb-0 no-uppercase"
                v-if="message.settings.require_response"
              >
                {{ $t("RESPONSE") }}
              </h6>
              <span v-if="message.settings.require_response">{{
                $t("MESSAGES.REQUESTED_RESPONSE")
              }}</span>

              <hr class="pb-0 mb-0 mt-2" />
            </div>
          </b-sidebar>

          <b-sidebar
            id="message-eventlog"
            v-model="showFunctionboxLog"
            lazy
            right
            no-slide
            shadow
            backdrop
          >
            <div class="px-3 py-2">
              {{ $t("LOGG") }}

              <hr class="pb-0 mb-0 mt-2 mb-3" />
              <EventLog
                :message_uuid="message_uuid"
                type="message-functionbox"
                :functionbox_uuid="functionbox_uuid"
                v-if="functionbox_uuid"
              ></EventLog>
            </div>
          </b-sidebar>

          <b-sidebar
            id="message-eventlog-useruuid"
            v-model="showDelegatedAccountLog"
            lazy
            right
            no-slide
            shadow
            backdrop
          >
            <div class="px-3 py-2">
              {{ $t("LOGG") }}

              <hr class="pb-0 mb-0 mt-2 mb-3" />
              <EventLog
                :message_uuid="message_uuid"
                type="message-delegated-account"
                :user_uuid="user.information.user_uuid"
                v-if="delegated_accounts.length != 0"
              ></EventLog>
            </div>
          </b-sidebar>

          <b-modal :dialog-class="teams.theme" :ok-title="$t('REMOVE')" :cancel-title="$t('CANCEL')" @ok="RemoveRecurringMeeting" hide-header ok-variant="danger" centered v-model="showRemoveMeeting" id="removeMeetingModal">
            <b-form-group>
              <b-form-radio v-model="selectRemoveRecurringMeeting" name="remove-recurring-all" value="SINGLE">{{ $t('REMOVE_RECURRENCE_SINGLE') }}</b-form-radio>
              <b-form-radio v-model="selectRemoveRecurringMeeting" name="remove-recurring-all" value="MULTIPLE">{{ $t('REMOVE_RECURRENCE_MULTIPLE') }}</b-form-radio>
              <b-form-radio v-model="selectRemoveRecurringMeeting" name="remove-recurring-all" value="ALL">{{ $t('REMOVE_RECURRENCE_ALL') }}</b-form-radio>              
            </b-form-group>
          </b-modal>
     

        </div>
        <iframe
          name="print_frame"
          width="0"
          height="0"
          frameborder="0"
          src="about:blank"
        ></iframe>
        <div v-if="showOldMessages">
          <div class="text-center mb-2">
            <b-button
              class="no-border"
              v-if="responses_before > 0"
              @click="showConversation"
            >
              <i class="fal fa-chevron-double-down"></i> &nbsp;
              {{ responses_before }} {{ $t("CONVERSATION_OLDER_MESSAGES") }}
            </b-button>
          </div>
        </div>
      </div>
      <div v-if="error">
        <b-button
          v-if="showCloseBtn"
          @click="closeMessage"
          class="no-border"
          v-b-tooltip.hover
          :title="$t('CLOSE')"
        >
          <i class="fal fa-times"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("CLOSE") }}</span>
        </b-button>
        <b-button
          @click="getInformation"
          class="no-border"
          v-if="!isConversation"
        >
          <i class="fal fa-sync"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("UPDATE") }}</span>
        </b-button>
        <b-button
          @click="removeMessage"
          class="no-border"
          v-if="!isConversation && errorMessage != 'ERROR.RECALLED'"
        >
          <i class="fal fa-trash-alt"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("REMOVE") }}</span>
        </b-button>

        <b-card class="mt-2 card-message" ref="printBody">
          {{ $t('ERROR.COULD_NOT_OPEN') }}
        </b-card>


      </div>
    </b-overlay>
    <LoaLevel :loa_level="loa_error" v-if="!isConversation"></LoaLevel>


  </section>
</template>
<script>
import Recipients from "./Recipients/All";
import ChangeMeeting from "../Compose/ChangeMeeting";
import AddMessageForward from "../Compose/AddMessageForward";
import AddMessageReply from "../Compose/AddMessageReply";
import DelegatedAccounts from "./DelegatedAccounts";
import EventLog from "../EventLog.vue";
import FunctionBoxAccounts from "./FunctionBoxAccounts";
import RegularMessage from "./Information/RegularMessage";
import SystemMessage from "./Information/SystemMessage";
import NuiteqMeeting from "./Information/NuiteqMeeting";
import JitsiMeeting from "./Information/JitsiMeeting";
import JitsiSecureMeeting from "./Information/JitsiSecureMeeting";
import TeamsMeeting from "./Information/TeamsMeeting";
import SdkMessage from "./Information/SdkMessage";
import FaxMessage from "./Information/FaxMessage";
import UserInfo from "@/components/Layout/UserInfo";
import LoaLevel from "./LoaLevel";
export default {
  name: "MessageInformation",
  props: {
    message_uuid: {
      default: "",
      type: String,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    showCloseBtn: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isConversation: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    startMeeting: {
      default: null,
      type: String,
    },
    endMeeting: {
      default: null,
      type: String,
    },
  },
  components: {
    ChangeMeeting,
    AddMessageForward,
    AddMessageReply,
    EventLog,
    DelegatedAccounts,
    RegularMessage,
    TeamsMeeting,
    SdkMessage,
    LoaLevel,
    UserInfo,
    Recipients,
    JitsiMeeting,
    JitsiSecureMeeting,
    FunctionBoxAccounts,
    NuiteqMeeting,
    FaxMessage,
    SystemMessage,
  },
  data() {
    return {
      selectRemoveRecurringMeeting: "SINGLE",
      showRemoveMeeting: false,
      startDate: this.startMeeting,
      endDate: this.endMeeting,
      filestorage: false,
      showRecalled: false,
      response_to_message_uuid: "",
      last_response_uuid: "",
      responses_after: 0,
      responses_before: 0,
      showAddConversation: false,
      conversation_uuid: "",
      showAllResponse: false,
      loa_error: 0,
      show: false,
      showFunctionboxLog: false,
      showDelegatedAccountLog: false,
      opened: false,
      error: false,
      loading: true,
      email: "",
      participants: [],
      external_status: [],
      external_system_participants: [],
      errorMessage: "",
      message: {},
      external: [],
      functionbox_accounts: [],
      delegated_accounts: [],
      recurrence: null
    };
  },
  methods: {
    gotoMessage() {
      this.$emit("gotoMessage", this.message.message_uuid);
    },
    UpdateInformation() {
      this.getInformation();
      this.getBeforeAfter();
    },
    showConversation() {
      this.$emit("showConversation", this.message.message_uuid);
    },
    createdResponse() {
      this.showAddConversation = false;
      this.getInformation();
    },
    cancelReply() {
      this.showAddConversation = false;
    },
    printBtn() {
      let start =
        '<html><head><meta http-equiv=cache-control content="max-age=0"><meta http-equiv=cache-control content=no-cache><meta http-equiv=expires content=0><meta http-equiv=expires content="Tue, 01 Jan 1980 1:00:00 GMT"><meta http-equiv=pragma content=no-cache><meta charset=utf-8><meta http-equiv=X-UA-Compatible content="IE=edge"><meta name=viewport content="width=device-width,initial-scale=1"><title>SEFOS</title><link type=text/css rel=stylesheet href=/css/bootstrap.min.css><link rel=apple-touch-icon sizes=180x180 href=/favicon/apple-touch-icon.png><link rel=icon type=image/png sizes=32x32 href=/favicon/favicon-32x32.png><link rel=icon type=image/png sizes=16x16 href=/favicon/favicon-16x16.png><link rel=manifest href=/favicon/site.webmanifest><link rel=stylesheet href=/css/animate.min.css><link rel=stylesheet href=/css/printer.css></head><body>';
      var content = document.getElementById("printMe").innerHTML;
      content = content.replace(
        /(<(pre|script|style|textarea|div)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/g,
        "$1$3"
      );
      content = content.replaceAll("\n", "<br>");
      window.frames["print_frame"].document.body.innerHTML =
        start + content + "</body></html>";
      window.frames["print_frame"].window.focus();
      setTimeout(function () {
        window.frames["print_frame"].window.print();
      }, 500);
    },
    removeMeeting(){
      if(this.recurrence)
      {
        this.showRemoveMeeting = true;   
      }else{
        let self = this;
      const h = this.$createElement;
      let messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
        h(
          "p",
          { class: ["text-center mb-0 pb-0"] },
          self.$t("CONFIRM.DO_YOU_WANT_TO_REMOVE", {
            msg: self.$t("MESSAGES.TYPE." + self.message.message_type),
          })
        ),
      ]);
      if (this.isCreator) {
        messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
          h(
            "p",
            { class: ["text-center mb-0 pb-0"] },
            self.$t("CONFIRM.DO_YOU_WANT_TO_REMOVE_CREATOR", {
              msg: self.$t("MESSAGES.TYPE." + self.message.message_type),
            })
          ),
        ]);
      }
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.RemoveRecurrence("ALL");
          }
        })
        .catch(function () {});
      }
    },
    async RemoveRecurringMeeting(){
      this.RemoveRecurrence(this.selectRemoveRecurringMeeting);
    },
    async RemoveRecurrence(type)
    {
      try {
        await this.$http.post(this.user.hostname + "/meetings/remove", {
          messageUuid: this.message_uuid,
          currentStartDate: this.startDate != null ? this.startDate : this.message.settings.meeting_start_date,
          affectSubsequent: type == "MULTIPLE" ? true : false,
          affectAll: type == "ALL" ? true : false
        });
        this.$emit("closeMessage");
      } catch {
        console.log("Could not RemoveRecurrenceMultipleMeetings");
      }
    },
    removeMessage() {
      let self = this;
      const h = this.$createElement;
      let messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
        h(
          "p",
          { class: ["text-center mb-0 pb-0"] },
          self.$t("CONFIRM.DO_YOU_WANT_TO_REMOVE", {
            msg: self.$t("MESSAGES.TYPE." + self.message.message_type),
          })
        ),
      ]);
      if (this.isCreator) {
        messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
          h(
            "p",
            { class: ["text-center mb-0 pb-0"] },
            self.$t("CONFIRM.DO_YOU_WANT_TO_REMOVE_CREATOR", {
              msg: self.$t("MESSAGES.TYPE." + self.message.message_type),
            })
          ),
        ]);
      }
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function (value) {
          if (value) {            

            await self.$http.post(self.user.hostname + "/message/remove", {
              message_uuid: self.message_uuid,
              user_uuid: self.user_uuid,
              functionbox_uuid: self.functionbox_uuid,
            });
            self.$emit("closeMessage");

          }
        })
        .catch(function () {});
    },
    closeMessage: function () {
      this.$emit("closeMessage");
    },
    async getBeforeAfter() {
      if(!this.IsMeeting(this.message.message_type))
      {
        try{
          let response = await this.$http
            .post(this.user.hostname + "/message/conversation/before-after", {
              message_uuid: this.message_uuid,
              functionbox_uuid: this.functionbox_uuid,
              user_uuid: this.user_uuid,
              page: 1,
              limit: 1,
            });          
          this.responses_after = response.data.after;
          this.responses_before = response.data.before;
        } catch {
          console.log("Could not fetch getBeforeAfter()");
        }
      }
    },
    async claim() {
      try {      
        await this.$http
        .post(this.user.hostname + "/message/functionbox/claim", {
          message_uuid: this.message_uuid,
          functionbox_uuid: this.functionbox_uuid,
        });
        this.getInformation();
      } catch {
        console.log("Could not claim()");
      }
    },
    async noClaim() {
      try {
        await this.$http
        .post(this.user.hostname + "/message/functionbox/claim/remove", {
          message_uuid: this.message_uuid,
          functionbox_uuid: this.functionbox_uuid,
        });
        this.getInformation();
      } catch {
        console.log("Could not noClaim()");
      }
    },
    archive: function () {
      let self = this;
      const h = this.$createElement;
      let messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
        h(
          "p",
          { class: ["text-center mb-0 pb-0"] },
          self.$t("CONFIRM.DO_YOU_WANT_TO_CLOSE", {
            msg: self.$t("MESSAGES.TYPE." + self.message.message_type),
          })
        ),
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/message/user/archive", {
                message_uuid: self.message_uuid,
                user_uuid: self.user_uuid,
              })
              .then(() => {
                self.getInformation();
              })
              .catch(() => {});
          }
        })
        .catch(function () {});
    },
    async closeIssue() {
      try {
        await this.$http
          .post(this.user.hostname + "/message/functionbox/archive", {
            message_uuid: this.message_uuid,
            functionbox_uuid: this.functionbox_uuid,
          });
          this.getInformation();
      } catch {
        console.log("Could not closeIssue()");
      }
    },
    noArchive: function () {
      let self = this;
      if (this.functionbox_uuid != "") {
        this.$http
          .post(this.user.hostname + "/message/functionbox/archive/remove", {
            message_uuid: this.message_uuid,
            functionbox_uuid: this.functionbox_uuid,
          })
          .then(() => {
            self.getInformation();
          })
          .catch(() => {});
      } else {
        this.$http
          .post(this.user.hostname + "/message/user/archive/remove", {
            message_uuid: this.message_uuid,
            user_uuid: this.user_uuid,
          })
          .then(() => {
            self.getInformation();
          })
          .catch(() => {});
      }
    },
    async updateUser() {
      await this.$store.dispatch("user/fetchUser");
    },
    async checkFilestorage() {
      let result = await this.$http
        .get(this.user.hostname + "/filestorage/enabled");
      this.filestorage = result.data;
    },
    async getInformation(){
      this.loa_error = 0;
      this.error = false;
      this.opened = false;
      try  {
        let response = await this.$http.post(this.user.hostname + "/message/uuid/open", {
                                              message_uuid: this.message_uuid,
                                              functionbox_uuid: this.functionbox_uuid,
                                              user_uuid: this.user_uuid,
                                            });
                                            console.log(response);
        this.conversation_uuid = response.data.conversation_uuid;
        this.message = response.data.message;
        this.participants = response.data.participants;
        this.external_system_participants = response.data.external_system_participant;
        this.external = response.data.external;
        this.functionbox_accounts = response.data.functionbox_accounts;
        this.delegated_accounts = response.data.delegated_accounts;
        this.recurrence = response.data.recurrence;
        this.opened = true;
        this.loading = false;
        this.$emit("closeConversation");
        this.response_to_message_uuid = this.message.message_uuid;
        if (this.message.response_to_message != "") {
          this.response_to_message_uuid = this.message.response_to_message;
        }
        this.updateUser();
        this.checkFilestorage();
        if (!this.isConversation) {
          this.getBeforeAfter();
        }
        if(this.startDate == null)
        {
          this.startDate = this.$route.query.startDate == null ? null : this.$route.query.startDate;
        }
        if(this.endDate == null)
        {
          this.endDate = this.$route.query.endDate == null ? null : this.$route.query.endDate;
        }
      } catch (e) {
        this.loading = false;
        this.opened = false;
        this.error = true;
        let loa_too_low = this.$store.state.errors.validations.filter(function (item) { return item.validation == 'LOA_TOO_LOW' });
        if(loa_too_low.length != 0){
          this.loa_error = loa_too_low[0].data;
        }        
        console.log("Could not fetch information");
      }
    },
  },
  computed: {
    showNewMessages() {      
      if(!this.message)
      {
        return false;
      }
      if(this.IsMeeting(this.message.message_type))
      {
        return false;
      }
      if (this.isConversation) {
        return false;
      }
      if (this.responses_after == 0) {
        return false;
      }
      return true;
    },
    showOldMessages() {
      if (this.isConversation) {
        return false;
      }
      if (this.responses_before == 0) {
        return false;
      }
      return true;
    },
    iconImage() {
      return "/img/message_type/" + this.message.message_type + ".png";
    },
    showArchived() {
      let self = this;
      if(this.IsMeeting(this.message.message_type))
      {
        return false;
      }
      if (self.isCreator) {
        return self.message.status == this.$const.MessageStatus.ARCHIVED;
      } else {
        if (self.functionbox_uuid != "") {
          let me = self.participants.filter(
            (c) => c.user_uuid == self.functionbox_uuid
          );
          if (me.length > 0) {
            return me[0].archived == 1;
          } else {
            return false;
          }
        } else {
          if (self.user_uuid != "") {
            let me = self.participants.filter(
              (c) => c.user_uuid == self.user_uuid
            );
            return me[0].archived == 1;
          } else {
            let me = self.participants.filter(
              (c) => c.user_uuid == self.user.information.user_uuid
            );
            return me[0].archived == 1;
          }
        }
      }
    },
    direktLink() {
      if (this.functionbox_uuid != "") {
        return (
          "/message/" +
          this.message.message_uuid +
          "/f/" +
          this.functionbox_uuid
        );
      } else {
        if (this.user_uuid != "") {
          return (
            "/message/" + this.message.message_uuid + "/u/" + this.user_uuid
          );
        } else {
          if(this.startDate != null && this.endDate == null){
            return "/message/" + this.message.message_uuid + "?startDate=" + this.startDate;  
          }            
          if(this.startDate == null && this.endDate != null){
            return "/message/" + this.message.message_uuid + "?endDate=" + this.endDate;  
          }            
          if(this.startDate != null && this.endDate != null){
            return "/message/" + this.message.message_uuid + "?startDate=" + this.startDate + "&endDate=" + this.endDate;
          }            
          return "/message/" + this.message.message_uuid;
        }
      }
    },
    isResponse() {
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        if (
          this.message.message_type == "response" ||
          this.message.message_type == "link-response"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    regular_message() {
      if (
        this.message.message_type == "message" ||
        this.message.message_type == "response" ||
        this.message.message_type == "creator-response" ||
        this.message.message_type == "link-response" ||
        this.message.message_type == "creator-link-response"
      ) {
        return true;
      } else {
        return false;
      }
    },
    responseType() {
      return (
        this.message.message_type == "response" ||
        this.message.message_type == "link-response"
      );
    },
    createdTime() {
      return this.FormatDateTime(this.message.created_date);
    },
    createdName() {
      if (this.message.creator.name == "") {
        return this.message.creator.email;
      } else {
        if (this.validatePhone(this.message.creator.name)) {
          return this.message.creator.name;
        } else {
          return (
            this.message.creator.name +
            ", " +
            this.message.creator.organisation_name
          );
        }
      }
    },
    isCreator() {
      if (this.functionbox_uuid != "") {
        if (this.message.creator != undefined) {
          return this.message.creator.user_uuid == this.functionbox_uuid;
        } else {
          return false;
        }
      } else {
        if (this.user_uuid != "") {
          if (this.message.creator != undefined) {
            return this.message.creator.user_uuid == this.user_uuid;
          } else {
            return false;
          }
        } else {
          if (this.message.creator != undefined) {
            return (
              this.message.creator.user_uuid == this.user.information.user_uuid
            );
          } else {
            return false;
          }
        }
      }
    },
    isCreatorParticipant() {      
      if(this.message.message_type == "sdk-message")
      {
        if (this.functionbox_uuid != "") {
          let functionbox_uuid = this.functionbox_uuid;
          return this.participants.filter(
            (c) => c.user_uuid == functionbox_uuid
          ).length != 0;
        }else{        
          return false;
        }
      }else{
        return false;
      }
    },
    userIsCreator() {
      return this.user.information.user_uuid == this.message.creator.user_uuid;
    },
    functionBoxIsCreator() {
      return this.functionbox_uuid == this.message.creator.user_uuid;
    },
    DelegatedBoxIsCreator() {
      return this.user_uuid == this.message.creator.user_uuid;
    },
    showRecipients() {
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        if (this.message.message_type == "fax-message") {
          return false;
        }
        if (this.message.message_type == "sdk-message") {
          return false;
        }
        return true;
      }
    },
    functionBoxCreatedBy() {
      let me = this.functionbox_accounts.filter((c) => c.creator == 1);
      if (me.length != 0) {
        return me[0].email;
      }
      return "";
    },
    delegatedCreatedBy() {
      let me = this.delegated_accounts.filter((c) => c.creator == 1);
      if (me.length != 0) {
        return me[0].email;
      }
      return "";
    },
    claimedBy() {
      if (this.showArchived) {
        return null;
      }
      if (this.isCreator && !this.isCreatorParticipant) {
        return null;
      }
      let claimedList = this.functionbox_accounts.filter(function (item) {
        return item.claimed == 1;
      });
      if (claimedList.length != 0) {
        return claimedList[0];
      } else {
        return null;
      }
    },
    haveIClaimed() {
      if (this.claimedBy != null) {
        if (this.message.creator.user_uuid == this.functionbox_uuid) {
          if(this.isCreatorParticipant){
            return this.claimedBy.email == this.user.information.email; 
          }else{
            return false;
          }
        } else {
          return this.claimedBy.email == this.user.information.email;
        }
      } else {
        return false;
      }
    },
    showRemoveClaim() {
      if (this.functionbox_uuid != "") {
        if (this.message.creator.user_uuid == this.functionbox_uuid) {
          if(this.isCreatorParticipant){
            return this.haveIClaimed;
          }else{
            return false;
          }
        } else {
          return this.haveIClaimed;
        }
      } else {
        return false;
      }
    },
    showClaim() {
      if (this.functionbox_uuid == "") { return false; }
      if (this.claimedBy == null) {
        if (this.message.creator.user_uuid != this.functionbox_uuid) {
          return true;
        } else {
          return this.isCreatorParticipant;
        }
      } else {
        if (this.claimedBy.email != this.user.information.email) {
          return true;
        } else {
          return false;
        }
      }
    },
    canRemove() {
      if (!this.IsMessage(this.message.message_type)) {
        return true;
      } else {
        if (this.user.information.organisation.users_can_remove == 0) {
          return false;
        } else {
          if (this.functionbox_uuid != "") {
            if (this.showClaim) {
              return true;
            } else {
              if (this.haveIClaimed) {
                return true;
              } else {
                if (this.isCreator) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          } else {
            return true;
          }
        }
      }
    },
    showRespond() {
      if(this.IsMeeting(this.message.message_type))
      {
        return false;
      }
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        if (this.message.message_type == "fax-message") {
          return !this.isCreator;
        }
        return (
          !this.isConversation &&
          !this.isCreator &&
          this.message.conversation_uuid != ""
        );
      }
    },
    showComplete() {
      if(this.IsMeeting(this.message.message_type))
      {
        return false;
      }
      if (this.message.message_type == "fax-message") {
        return this.isCreator;
      }
      if(this.isCreator && this.functionbox_uuid != "")
      {
        // API HAS SENT TO ITSELF WITHOUT ANY SEFOS OR EXTERNAL PARTICIPANT. DO NOT SHOW REPLY 
        if(this.ParticipantNoCreatorCount == 0)
        {
          return false;      
        }
      }
      return (
        !this.isConversation &&
        this.isCreator &&
        this.message.conversation_uuid != ""
      );
    },
    ParticipantNoCreatorCount(){
      let user_uuid = this.user.information.user_uuid;
      if(this.functionbox_uuid != "")
      {
        user_uuid = this.functionbox_uuid;
      }
      if(this.user_uuid != "")
      {
        user_uuid = this.user_uuid;
      }
      let participantsNoCreator = this.participants.filter((c) => c.user_uuid != user_uuid );
      return this.external.length + participantsNoCreator.length + this.external_system_participants.length;
    },
    showRespondAll() {
      if(this.IsMeeting(this.message.message_type))
      {
        return false;
      }
      if (!this.IsSefosMessage(this.message.message_type)) {
        return false;
      } else {
        return (
          !this.isConversation &&
          !this.isCreator &&
          this.message.conversation_uuid != ""
        );
      }
    },
    showForward() {
      if (this.message.message_type == "system-message") {
        return false;
      } else {
        if (this.IsMessage(this.message.message_type)) {
          return !this.isConversation;
        } else {
          return false;
        }
      }
    },
    showOpenIssueBtn(){
      return this.functionbox_uuid && this.showArchived && ((this.isCreator && this.isCreatorParticipant) || !this.isCreator) && this.IsMessage(this.message.message_type);
    },
    showCloseIssueBtn(){
      return this.functionbox_uuid && !this.showArchived && ((this.isCreator && this.isCreatorParticipant) || !this.isCreator) && this.IsMessage(this.message.message_type);              
    },
    showNoArchiveBtn(){      
      return !this.functionbox_uuid && this.showArchived && ((this.isCreator && this.isCreatorParticipant) || !this.isCreator) && this.IsMessage(this.message.message_type);              
    },
    showCloseConversationBtn(){
      return !this.functionbox_uuid && !this.showArchived && ((this.isCreator && this.isCreatorParticipant) || !this.isCreator) && this.IsMessage(this.message.message_type);
    },
    showRemoveClaimBtn(){
      return this.functionbox_uuid && this.showRemoveClaim && ((this.isCreator && this.isCreatorParticipant) || !this.isCreator) && !this.showArchived && this.IsMessage(this.message.message_type);              
    },
    showClaimBtn(){
      return this.functionbox_uuid && this.showClaim && ((this.isCreator && this.isCreatorParticipant) || !this.isCreator) &&  !this.showArchived && this.IsMessage(this.message.message_type);
    },
    mdcols() {
      return this.ParticipantNoCreatorCount != 0 && this.showRecipients && !this.isConversation && this.isCreator ? 6 : 12;
    },
    SdkConfidential(){
      if(!this.message){ return false; }
      if(this.message.message_type != "sdk-message") { return false; }      
      if(this.external_system_participants.length != 0){
        if(this.external_system_participants[0].metadata.Isconfidential != undefined)
        {
          return this.external_system_participants[0].metadata.Isconfidential; 
        }else{
          return this.external_system_participants[0].metadata.isconfidential;
        }
      }else{
        if(this.message.creator.external_system.metadata.IsConfidential != undefined)
        {
          return this.message.creator.external_system.metadata.IsConfidential;
        }else{
          return this.message.creator.external_system.metadata.isConfidential;
        }
      }
    }
  },
  watch: {
    message_uuid: function (val, oldval) {
      if (val != oldval) {
        this.getInformation();
      }
    },
  },
  mounted: function () {
    this.getInformation();
  },
};
</script>
<style></style>