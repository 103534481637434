var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.metadata
      ? _c("dl", { staticClass: "mt-2" }, [
          _c("dt", [_vm._v(_vm._s(_vm.$t("FROM")))]),
          _c("dd", [
            _vm._v(_vm._s(_vm.metadata.FromFaxName)),
            _vm.metadata.FromFaxName ? _c("span", [_vm._v(", ")]) : _vm._e(),
            _vm._v(_vm._s(_vm.metadata.FromFaxNumber.replace("00", "+"))),
          ]),
          _c("dt", [_vm._v(_vm._s(_vm.$t("TO")))]),
          _c("dd", [
            _vm._v(_vm._s(_vm.metadata.ToFaxName)),
            _vm.metadata.ToFaxName ? _c("span", [_vm._v(", ")]) : _vm._e(),
            _vm._v(_vm._s(_vm.metadata.ToFaxNumber.replace("00", "+"))),
          ]),
          1 == 0
            ? _c("div", [
                _vm.metadata.SenderFunctionBoxIdentifier
                  ? _c("dt", [_vm._v(_vm._s(_vm.$t("FAX.CLIENT_IDENTIFIER")))])
                  : _vm._e(),
                _vm.metadata.SenderFunctionBoxIdentifier
                  ? _c("dd", [
                      _vm._v(_vm._s(_vm.metadata.SenderFunctionBoxIdentifier)),
                    ])
                  : _vm._e(),
                _vm.metadata.RecipientFunctionBoxIdentifier
                  ? _c("dt", [_vm._v(_vm._s(_vm.$t("FAX.CLIENT_IDENTIFIER")))])
                  : _vm._e(),
                _vm.metadata.RecipientFunctionBoxIdentifier
                  ? _c("dd", [
                      _vm._v(
                        _vm._s(_vm.metadata.RecipientFunctionBoxIdentifier)
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }