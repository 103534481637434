<template>
  <section v-if="metadata">
        
    <hr class="pb-0 mb-0 mt-2" />

    <dl class="mt-2 clearfix" v-if="1 == 0">
      <dt>{{ $t("SDK.MessageId") }}</dt>
      <dd>{{ metadata.MessageId }}</dd>
      <dt v-if="metadata.RefToMessageId">{{ $t("SDK.RefToMessageId") }}</dt>
      <dd v-if="metadata.RefToMessageId">{{ metadata.RefToMessageId }}</dd>
      <dt>{{ $t("SDK.ConversationId") }}</dt>
      <dd>{{ metadata.ConversationId }}</dd>
    </dl>

    
    <b-tabs content-class="mt-3" nav-class="mt-2">
      <b-tab :active="!recipient" :title="$t('SENDER')">
        <b-card>
          <SdkSmallInformation
            v-if="!from"
            show-send
            :information="metadata"
            :hide-save="recipient"
            :functionbox_uuid="functionbox_uuid"
          ></SdkSmallInformation>

          <SDKInformation
            v-if="from"
            :item="from"
            hide-select
            hide-back
            hide-remove
            :hide-save="recipient"
            :functionbox_uuid="functionbox_uuid"
            @setItem="setItem"
            @selectedAddress="selectedAddress"
            @unselectAddress="unselectAddress"
            @backBtn="backBtn"
          ></SDKInformation>
        </b-card>
      </b-tab>
      <b-tab :active="recipient" :title="$t('RECIPIENT')">
        <b-card>
          <SdkSmallInformation
            v-if="!to"
            :information="metadata"
            :hide-save="!recipient"
            :functionbox_uuid="functionbox_uuid"
          ></SdkSmallInformation>
          <SDKInformation
            v-if="to"
            hide-select
            hide-back
            hide-remove
            :hide-save="!recipient"
            :item="to"
            :functionbox_uuid="functionbox_uuid"
            @setItem="setItem"
            @selectedAddress="selectedAddress"
            @unselectAddress="unselectAddress"
            @backBtn="backBtn"
          ></SDKInformation>
        </b-card>
      </b-tab>
      <b-tab :title="$t('INFORMATION')" v-if="showinfo">
        <SDKPersonId
          :key="fromParticipantPersonsKey"
          :items="fromParticipantPersons"
          >{{ $t("SDK.PERSONID.EXTENSION.SENDER.TITLE") }}</SDKPersonId
        >
        <SdkReferensId
          :key="fromParticipantReferencesKey"
          :items="fromParticipantReferences"
          >{{ $t("SDK.REFERENCEID.EXTENSION.SENDER.TITLE") }}</SdkReferensId
        >
        <SDKPersonId
          :key="toParticipantPersonsKey"
          :items="toParticipantPersons"
          >{{ $t("SDK.PERSONID.EXTENSION.RECIPIENT.TITLE") }}</SDKPersonId
        >
        <SdkReferensId
          :key="toParticipantReferencesKey"
          :items="toParticipantReferences"
          >{{ $t("SDK.REFERENCEID.EXTENSION.RECIPIENT.TITLE") }}</SdkReferensId
        >
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import SdkSmallInformation from "../../..//Input/SDK/SdkSmallInformation";
import SDKInformation from "../../../Input/SDK/SdkInformation";
import SDKPersonId from "../..//Information/Metadata/SdkPersonId";
import SdkReferensId from "../../Information/Metadata/SdkReferensId";
export default {
  props: {
    functionbox_uuid: {
      default: "",
      type: String,
    },
    recipient: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    metadata: {
      default: null,
      type: Object,
    },
  },
  components: {
    SDKPersonId,
    SdkReferensId,
    SDKInformation,
    SdkSmallInformation,
  },
  data() {
    return {
      from: null,
      to: null,
      addressinfo: null,
    };
  },
  methods: {
    async init() {
      let FromParticipantOrganisationId = "";
      if (this.metadata.FromParticipantOrganisationId != undefined) {
        FromParticipantOrganisationId = this.metadata.FromParticipantOrganisationId;
      }
      let ToParticipantOrganisationId = "";
      if (this.metadata.ToParticipantOrganisationId != undefined) {
        ToParticipantOrganisationId = this.metadata.ToParticipantOrganisationId;
      }
      if (this.metadata.addressBookToId == undefined) {
        this.from = await this.getAddressInfo(
          this.metadata.FromParticipantFunctionId,
          FromParticipantOrganisationId
        );
      }
      if (this.metadata.addressBookFromId == undefined) {
        this.to = await this.getAddressInfo(
          this.metadata.ToParticipantFunctionId,
          ToParticipantOrganisationId
        );
      }
    },
    backBtn() {},
    selectedAddress() {},
    unselectAddress() {},
    setItem() {},
    async getAddressInfo(identifier, organisation) {
      return new Promise((resolve) => {
        let SdkIdentifierDTO = {
          identifier: identifier,
          organisation: organisation,
          name: "",
          functionbox_uuid: this.functionbox_uuid,
        };
        this.$http
          .post(
            this.user.hostname + "/sdk/address/identifier",
            SdkIdentifierDTO
          )
          .then(function (result) {
            if (Array.isArray(result.data)) {
              resolve(result.data[0]);
            } else {
              resolve(result.data);
            }
          })
          .catch(function () {
            resolve(null);
          });
      });
    },
  },
  computed: {
    showinfo() {
      if (
        this.fromParticipantPersons.length != 0 ||
        this.fromParticipantReferences.length != 0 ||
        this.toParticipantPersons.length != 0 ||
        this.toParticipantReferences.length != 0
      ) {
        return true;
      }
      return false;
    },
    fromParticipantPersons() {
      return this.metadata.FromParticipantPersons == null
        ? []
        : this.metadata.FromParticipantPersons;
    },
    fromParticipantReferences() {
      return this.metadata.FromParticipantReferences == null
        ? []
        : this.metadata.FromParticipantReferences;
    },
    toParticipantPersons() {
      return this.metadata.ToParticipantPersons == null
        ? []
        : this.metadata.ToParticipantPersons;
    },
    toParticipantReferences() {
      return this.metadata.ToParticipantReferences == null
        ? []
        : this.metadata.ToParticipantReferences;
    },
    toParticipantPersonsKey() {
      return "toParticipantPersonsKey_" + this.toParticipantPersons.length;
    },
    toParticipantReferencesKey() {
      return (
        "toParticipantReferencesKey_" + this.toParticipantReferences.length
      );
    },
    fromParticipantPersonsKey() {
      return "fromParticipantPersonsKey_" + this.fromParticipantPersons.length;
    },
    fromParticipantReferencesKey() {
      return (
        "fromParticipantReferencesKey_" + this.fromParticipantReferences.length
      );
    },
  },
  watch: {},
  mounted: function () {
    this.init();
  },
};
</script>
<style></style>
