var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.metadata
    ? _c(
        "section",
        [
          _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
          1 == 0
            ? _c("dl", { staticClass: "mt-2 clearfix" }, [
                _c("dt", [_vm._v(_vm._s(_vm.$t("SDK.MessageId")))]),
                _c("dd", [_vm._v(_vm._s(_vm.metadata.MessageId))]),
                _vm.metadata.RefToMessageId
                  ? _c("dt", [_vm._v(_vm._s(_vm.$t("SDK.RefToMessageId")))])
                  : _vm._e(),
                _vm.metadata.RefToMessageId
                  ? _c("dd", [_vm._v(_vm._s(_vm.metadata.RefToMessageId))])
                  : _vm._e(),
                _c("dt", [_vm._v(_vm._s(_vm.$t("SDK.ConversationId")))]),
                _c("dd", [_vm._v(_vm._s(_vm.metadata.ConversationId))]),
              ])
            : _vm._e(),
          _c(
            "b-tabs",
            { attrs: { "content-class": "mt-3", "nav-class": "mt-2" } },
            [
              _c(
                "b-tab",
                { attrs: { active: !_vm.recipient, title: _vm.$t("SENDER") } },
                [
                  _c(
                    "b-card",
                    [
                      !_vm.from
                        ? _c("SdkSmallInformation", {
                            attrs: {
                              "show-send": "",
                              information: _vm.metadata,
                              "hide-save": _vm.recipient,
                              functionbox_uuid: _vm.functionbox_uuid,
                            },
                          })
                        : _vm._e(),
                      _vm.from
                        ? _c("SDKInformation", {
                            attrs: {
                              item: _vm.from,
                              "hide-select": "",
                              "hide-back": "",
                              "hide-remove": "",
                              "hide-save": _vm.recipient,
                              functionbox_uuid: _vm.functionbox_uuid,
                            },
                            on: {
                              setItem: _vm.setItem,
                              selectedAddress: _vm.selectedAddress,
                              unselectAddress: _vm.unselectAddress,
                              backBtn: _vm.backBtn,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-tab",
                {
                  attrs: { active: _vm.recipient, title: _vm.$t("RECIPIENT") },
                },
                [
                  _c(
                    "b-card",
                    [
                      !_vm.to
                        ? _c("SdkSmallInformation", {
                            attrs: {
                              information: _vm.metadata,
                              "hide-save": !_vm.recipient,
                              functionbox_uuid: _vm.functionbox_uuid,
                            },
                          })
                        : _vm._e(),
                      _vm.to
                        ? _c("SDKInformation", {
                            attrs: {
                              "hide-select": "",
                              "hide-back": "",
                              "hide-remove": "",
                              "hide-save": !_vm.recipient,
                              item: _vm.to,
                              functionbox_uuid: _vm.functionbox_uuid,
                            },
                            on: {
                              setItem: _vm.setItem,
                              selectedAddress: _vm.selectedAddress,
                              unselectAddress: _vm.unselectAddress,
                              backBtn: _vm.backBtn,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showinfo
                ? _c(
                    "b-tab",
                    { attrs: { title: _vm.$t("INFORMATION") } },
                    [
                      _c(
                        "SDKPersonId",
                        {
                          key: _vm.fromParticipantPersonsKey,
                          attrs: { items: _vm.fromParticipantPersons },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("SDK.PERSONID.EXTENSION.SENDER.TITLE")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "SdkReferensId",
                        {
                          key: _vm.fromParticipantReferencesKey,
                          attrs: { items: _vm.fromParticipantReferences },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("SDK.REFERENCEID.EXTENSION.SENDER.TITLE")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "SDKPersonId",
                        {
                          key: _vm.toParticipantPersonsKey,
                          attrs: { items: _vm.toParticipantPersons },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("SDK.PERSONID.EXTENSION.RECIPIENT.TITLE")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "SdkReferensId",
                        {
                          key: _vm.toParticipantReferencesKey,
                          attrs: { items: _vm.toParticipantReferences },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "SDK.REFERENCEID.EXTENSION.RECIPIENT.TITLE"
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }