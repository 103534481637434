<template>
  <span>
    <a href="#" @click="show = true" class="clickable"><slot></slot></a>
    <b-sidebar
      id="message-functionbox-accounts"
      v-model="show"
      lazy
      right
      no-slide
      shadow
      backdrop
    >
      <div class="px-3 py-2">
        {{ $t("USERS") }}

        <hr class="pb-0 mb-0 mt-2 mb-3" />

        <b-table
          class="w-100 mt-2 mb-0 pb-0"
          fixed
          hover
          small
          :fields="fields"
          :items="functionbox_accounts"
        > <template v-slot:cell(fetched_date)="data">
            <span v-if="data.item.creator == 1">{{$t('CREATED')}}</span>
            <span v-if="data.item.creator == 0">{{$t('OPENED')}} {{            
              FormatDateTime(data.item.fetched_date)
          }}</span></template>

          <template v-slot:cell(email)="data">
            {{ data.item.email }}
          </template>

        </b-table>
      </div>
    </b-sidebar>
  </span>
</template>
<script>
export default {
  components: {},
  props: ["message_uuid", "functionbox_uuid", "functionbox_accounts"],
  data() {
    return {
      loading: false,
      visible: false,
      show: false,
      fields: [
        {
          key: "email",
          label: "Email",
          class: "text-break hidden-mobile",
          thClass: "w-150",
        },
        {
          key: "fetched_date",
          label: this.$t("STATE"),
          class: "text-break hidden-mobile",
          thClass: "w-150",
        },
      ]
    };
  },
  methods: {
    getStatus(item) {
      if(item.creator == 1)
      {
        return 100;
      }
      if (item.claimed == 1) {
        return 2;
      }
      if (item.fetched == 1) {
        return 1;
      }
      return 0;
    },
    showStatus(item) {
      if (item.fetched == 1) {
        return this.$t("VIEWED");
      }
      return this.$t("NOT_VIEWED");
    },
  },
  mounted() {},
};
</script>
<style></style>
