var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "a",
        {
          staticClass: "clickable",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              _vm.show = true
            },
          },
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "message-functionbox-accounts",
            lazy: "",
            right: "",
            "no-slide": "",
            shadow: "",
            backdrop: "",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [
              _vm._v("\n      " + _vm._s(_vm.$t("USERS")) + "\n\n      "),
              _c("hr", { staticClass: "pb-0 mb-0 mt-2 mb-3" }),
              _c("b-table", {
                staticClass: "w-100 mt-2 mb-0 pb-0",
                attrs: {
                  fixed: "",
                  hover: "",
                  small: "",
                  fields: _vm.fields,
                  items: _vm.functionbox_accounts,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(fetched_date)",
                    fn: function (data) {
                      return [
                        data.item.creator == 1
                          ? _c("span", [_vm._v(_vm._s(_vm.$t("CREATED")))])
                          : _vm._e(),
                        data.item.creator == 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("OPENED")) +
                                  " " +
                                  _vm._s(
                                    _vm.FormatDateTime(data.item.fetched_date)
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(email)",
                    fn: function (data) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(data.item.email) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }