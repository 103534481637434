<template>
  <section>
    <div id="meet"></div>

    <div class="d-flex">
      <div style="width: 12rem" class="ml-0 pl-0">
        <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("FROM") }}</h4>
        {{
          startMeeting == null
            ? FormatDateTime(message.settings.meeting_start_date)
            : FormatDateTime(startMeeting)
        }}
      </div>
      <div style="width: 12rem" class="ml-0 pl-0">
        <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("TO") }}</h4>
        {{
          endMeeting == null
            ? FormatDateTime(message.settings.meeting_end_date)
            : FormatDateTime(endMeeting)
        }}
      </div>
    </div>

    <hr class="pb-0 mb-0 mt-2" v-if="recurrence" />

    <PickRecurrence class="mt-2"
      v-if="recurrence"
      :recurrence="recurrence"
      :startDate="message.settings.meeting_start_date"
      :endDate="message.settings.meeting_end_date"
      text-only
    ></PickRecurrence>

    <hr class="pb-0 mb-0 mt-2" v-if="attachments.length != 0" />

    <div class="mt-2" v-if="attachments.length != 0">
      <span
        v-for="(item, index) in attachments"
        :key="index"
        class="ms-fontSize-12"
      >
        <Attachment
          :item="item"
          :index="index"
          :message_uuid="message.message_uuid"
          :user_uuid="user_uuid"
          :filestorage="filestorage"
          :functionbox_uuid="functionbox_uuid"
        ></Attachment>
      </span>
    </div>
    
    <hr class="pb-0 mb-0 mt-2" />

    <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("CONTENT") }}</h4>

    <BodyText :body="message.body"></BodyText>

    <h4 class="m-0 p-0 mt-2 mb-1 link-header">{{ $t("DESCRIPTION") }}</h4>

    <BodyText :body="message.external_text"></BodyText>

    <hr class="pb-0 mb-0 mt-2 mb-2" />

    <b-button variant="primary" class="btn-fill" @click="openMeeting">
      {{ $t("SECURELINK.JITSI.JOIN_MEETING") }}
    </b-button>
  </section>
</template>
<script>
import PickRecurrence from "../../Input/PickRecurrence";
import Attachment from "../Attachment";
import BodyText from "../BodyText";
export default {
  name: "MessageInformation",
  props: [
    "filestorage",
    "message",
    "user_uuid",
    "functionbox_uuid",
    "startMeeting",
    "endMeeting",
    "recurrence"
  ],
  components: {
    Attachment,
    BodyText,
    PickRecurrence,
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    async openMeeting() {
      try {
        let result = await this.$http.get(
          this.user.hostname + "/jitsi/join-user/" + this.message.message_uuid
        );
        let meetingData = result.data
        let meetingSubject = '"' + this.message.subject + '"';
        window
          .open(
            "https://" +
              meetingData.server +
              "/" +
              meetingData.room +
              "?jwt=" +
              meetingData.token +
              "#config.disableDeepLinking=true&config.subject=" + meetingSubject,
            "_blank"
          )
          .focus();
      } catch {
        console.log("Could not openMeeting");
      }
    },
  },
  computed: {
    isFinished() {
      return this.current > this.expire;
    },
    expire() {
      let date = new Date(this.message.settings.open_to_date);
      return date;
    },
    current() {
      let date = new Date();
      return date;
    },
    attachments() {
      return this.message.attachments;
    },
    addIcs() {
      return this.user.hostname + "/jitsi/ics/" + this.message.message_uuid;
    },
  },
  watch: {},
  mounted: function () {},
};
</script>
<style></style>
