<template>
  <section>

    <BodyText :body="message.body"></BodyText>

    <div  v-if="message.attachments.length != 0">
      <hr class="pb-0 mb-0 mt-2" />

      <div class="info-header mt-2">{{ $t("ATTACHMENTS") }}</div>

      <div class="mt-2">
        <span v-for="(item, index) in message.attachments" :key="index">
          <Attachment
            :item="item"
            :message_uuid="message.message_uuid"
            :user_uuid="user_uuid"
            :filestorage="filestorage"
            :functionbox_uuid="functionbox_uuid"
            :index="index"
          ></Attachment>
        </span>
      </div>    
    </div>

  </section>
</template>
<script>
import Attachment from "../Attachment";
import BodyText from "../BodyText";
export default {
  name: "MessageInformation",
  props: ["filestorage","message","user_uuid", "functionbox_uuid"],
  components: {
    Attachment,
    BodyText,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {},
  watch: {},
  mounted: function() {},
};
</script>
<style></style>
