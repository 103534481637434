<template>
  <span v-if="info">
    <ContactInformation :no-lookup="noLookup" :user_uuid="user_uuid" :functionbox_uuid="functionbox_uuid" :identifier="info.email"></ContactInformation>
    <span v-if="!islast">;&nbsp;</span></span>
</template>
<script>
import ContactInformation from "../ContactInformation";
export default {
  props: {
    islast: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    noLookup: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    item: {
      default: null,
      type: [Object, String],
    },
    message_type: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },    
    functionbox_uuid: {
      default: "",
      type: String,
    }
  },
  components: {
    ContactInformation
  },
  data() {
    return {
      show: false,
      info: this.item,
      loading: false,
      showDialog: false,
      viewed: false,
      viewed_date: "",
      showResponse: false,
      count: 0
    };
  },
  methods: {
  },
  computed: {
  },
  mounted(){    
  }
};
</script>
<style></style>