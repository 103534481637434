<template>
  <tr>
    <a href="#" @click="show = true" v-b-tooltip.hover :title="showStatus">
      <MessageStatus :status="item.status"></MessageStatus> {{ name }}
      <span v-if="!islast">;&nbsp;&nbsp;</span></a
    >
    <b-sidebar
      id="message-participant"
      v-model="show"
      right
      lazy
      no-slide
      shadow
      backdrop
    >
      <div class="px-3 py-2">
        <b-overlay :show="loading" rounded="sm">
          <UserInfo
            class="mt-4"
            image="/img/default-avatar.png"
            :primary="name"
          ></UserInfo>

          <hr class="pb-0 mb-0 mt-2" />

          <dl class="mt-2 clearfix">
            <dt>{{ $t("STATUS") }}</dt>
            <dd><MessageStatus :status="item.status" show-text></MessageStatus></dd>
          </dl>
          
        </b-overlay>
      </div>
    </b-sidebar>
  </tr>
</template>
<script>
import UserInfo from "../..//Layout/UserInfo";
import MessageStatus from "../MessageStatus";
export default {
  components: { MessageStatus, UserInfo, },
  props: ["response", "index", "item", "islast", "creator", "status", "functionbox_uuid"],
  data() {
    return {
      count: 0,
      show: false,
      info: this.item,
      loading: false,
      showDialog: false,
      viewed: false,
      viewed_date: "",
      showResponse: false
    };
  },
  methods: {
    viewResponse: function() {
      this.showResponse = true;
    },
    onShow: function() {
      this.$root.$emit("bv::hide::popover");
    },
    onClose: function() {
      this.showDialog = false;
    },
    recall: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
          self.$http
            .post(
              self.user.hostname + "/message/user/recall",
              {
                participant_id: self.info.id,
              }
            )
            .then(function(response) {
              self.loading = false;
              self.info.recalled = 1;
              self.info.recalled_date = response.data.recalled_date;
              self.$noty.info(self.$t("SAVED"));
            })
            .catch(function() {
              self.loading = false;
            });
        }
      })
      .catch(function(){
      }); 
    },
    resend: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
          self.$http
            .post(
              self.user.hostname + "/message/resend",
              {
                participant_id: self.info.id,
              }
            )
            .then(function() {
              self.loading = false;
              if(self.info.sent != undefined)
              {
                self.info.sent = 1;
              }
              self.$noty.info(self.$t("SENT"));
            })
            .catch(function() {
              self.loading = false;
            });
        }
      })
      .catch(function(){
      }); 
    }
  },
  computed: {
    name(){
      if(this.info.name != "")
      {
        return this.info.name;
      }
      if(this.info.email != "")
      {
        return this.info.email;
      }
      if( (this.info.email != "") && (this.info.name != "") )
      {
        return this.info.name + ", " + this.info.email;
      }
      return this.info.identifier;
    },
    responsed() {
      return this.info.response_message_uuid != "";
    },
    getStatus() {
      /*
      if (this.responsed) {
        return 3;
      }
      if (this.info.recalled == 1) {
        return 2;
      }
      if (this.info.fetched == 1) {
        return 1;
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return 10;
        }
      }*/
      return 0;
    },
    popoverTarget() {
      return "Participant" + this.index;
    },
    showStatus() {
      /*
      if (this.responsed) {
        return this.$t("RESPONSED");
      }
      if (this.info.recalled == 1) {
        return this.$t("RECALLED");
      }
      if (this.info.fetched == 1) {
        return this.$t("VIEWED");
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return this.$t("NOT_SENT");
        }
      }
      return this.$t("NOT_VIEWED");
      */
     return "";
    },
  },
  mounted(){
  }
};
</script>
<style></style>
