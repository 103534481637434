var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("div", { attrs: { id: "meet" } }),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "ml-0 pl-0", staticStyle: { width: "12rem" } },
          [
            _c("h4", { staticClass: "m-0 p-0 mt-2 mb-1 link-header" }, [
              _vm._v(_vm._s(_vm.$t("FROM"))),
            ]),
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.startMeeting == null
                    ? _vm.FormatDateTime(
                        _vm.message.settings.meeting_start_date
                      )
                    : _vm.FormatDateTime(_vm.startMeeting)
                ) +
                "\n    "
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "ml-0 pl-0", staticStyle: { width: "12rem" } },
          [
            _c("h4", { staticClass: "m-0 p-0 mt-2 mb-1 link-header" }, [
              _vm._v(_vm._s(_vm.$t("TO"))),
            ]),
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.endMeeting == null
                    ? _vm.FormatDateTime(_vm.message.settings.meeting_end_date)
                    : _vm.FormatDateTime(_vm.endMeeting)
                ) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm.recurrence ? _c("hr", { staticClass: "pb-0 mb-0 mt-2" }) : _vm._e(),
      _vm.recurrence
        ? _c("PickRecurrence", {
            staticClass: "mt-2",
            attrs: {
              recurrence: _vm.recurrence,
              startDate: _vm.message.settings.meeting_start_date,
              endDate: _vm.message.settings.meeting_end_date,
              "text-only": "",
            },
          })
        : _vm._e(),
      _vm.attachments.length != 0
        ? _c("hr", { staticClass: "pb-0 mb-0 mt-2" })
        : _vm._e(),
      _vm.attachments.length != 0
        ? _c(
            "div",
            { staticClass: "mt-2" },
            _vm._l(_vm.attachments, function (item, index) {
              return _c(
                "span",
                { key: index, staticClass: "ms-fontSize-12" },
                [
                  _c("Attachment", {
                    attrs: {
                      item: item,
                      index: index,
                      message_uuid: _vm.message.message_uuid,
                      user_uuid: _vm.user_uuid,
                      filestorage: _vm.filestorage,
                      functionbox_uuid: _vm.functionbox_uuid,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
      _c("h4", { staticClass: "m-0 p-0 mt-2 mb-1 link-header" }, [
        _vm._v(_vm._s(_vm.$t("CONTENT"))),
      ]),
      _c("BodyText", { attrs: { body: _vm.message.body } }),
      _c("h4", { staticClass: "m-0 p-0 mt-2 mb-1 link-header" }, [
        _vm._v(_vm._s(_vm.$t("DESCRIPTION"))),
      ]),
      _c("BodyText", { attrs: { body: _vm.message.external_text } }),
      _c("hr", { staticClass: "pb-0 mb-0 mt-2 mb-2" }),
      _c(
        "b-button",
        {
          staticClass: "btn-fill",
          attrs: { variant: "primary" },
          on: { click: _vm.openMeeting },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("SECURELINK.JITSI.JOIN_MEETING")) + "\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }