var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: { hover: true },
            },
          ],
          attrs: { href: "#", title: _vm.showStatus },
          on: {
            click: function ($event) {
              _vm.show = true
            },
          },
        },
        [
          _c("MessageStatus", { attrs: { status: _vm.item.status } }),
          _vm._v(" " + _vm._s(_vm.name) + "\n    "),
          !_vm.islast ? _c("span", [_vm._v(";  ")]) : _vm._e(),
        ],
        1
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "message-participant",
            right: "",
            lazy: "",
            "no-slide": "",
            shadow: "",
            backdrop: "",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c("UserInfo", {
                    staticClass: "mt-4",
                    attrs: {
                      image: "/img/default-avatar.png",
                      primary: _vm.name,
                    },
                  }),
                  _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                  _c("dl", { staticClass: "mt-2 clearfix" }, [
                    _c("dt", [_vm._v(_vm._s(_vm.$t("STATUS")))]),
                    _c(
                      "dd",
                      [
                        _c("MessageStatus", {
                          attrs: { status: _vm.item.status, "show-text": "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }